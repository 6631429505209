import { CONST_ROUTE_PATH } from "../../route/Routes";

/**
 * @description Parse google sign in response
 * @param {*} token 
 * @returns 
 */
export const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
   var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
   }).join(''));
 
   return JSON.parse(jsonPayload);
};

export const STORAGE_KEYS = {
  REDIRECT_URL: 'REDIRECTURL',
  LOCAL_LOGIN_TOKEN: 'apiToken',
}
export const REGISTRATION_TYPE = {
  MANUAL: 'NORMAL',
  GOOGLE: 'GOOGLE'
}

export const navigateToDashboardPostLogin = (navigateCallback) => {
  if (sessionStorage.getItem(STORAGE_KEYS.REDIRECT_URL) ) {
      navigateCallback(sessionStorage.getItem(STORAGE_KEYS.REDIRECT_URL))
    } else {
      navigateCallback(CONST_ROUTE_PATH.HOME)
    }
}