import React from 'react';
import { List } from '@mui/material';
import MessageItem from './MessageItem';

const MessageList = ({ messages, updateMessage,msg }) => {
  return (
    <List sx={{ borderRadius: '8px', padding: '8px' }}>
      {messages.length !== 0 && messages.map((message) => (
        <MessageItem key={message.id} message={message} updateMessage={updateMessage} messages={msg}/>
      ))}
    </List>
  );
};

export default MessageList;
