import './Styles/App.css';
import ChatProvider from './contexts/ChatContext';
import { CssBaseline } from '@mui/material';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import { Mainpage } from './Componants/Pages/Home/Mainpage';
import { CONST_ROUTE_PATH } from './route/Routes';
import  Signin  from './Componants/Pages/Auth/Signin';
// import  Signup  from './Componants/Pages/Auth/Signup';
import Structure from './Componants/Utils/Structure';
import ForgotPassword from './Componants/Pages/Auth/ForgotPassword';
import ProtectedRoute from './route/ProtectedRoute';



function App() {
  return (
    <ChatProvider>
    <BrowserRouter>
    <Routes>
    {/* <Route path={CONST_ROUTE_PATH.HOME} element={<Mainpage/>} /> */}
    <Route path={CONST_ROUTE_PATH.CHAT_AI} element={<ProtectedRoute><Structure/></ProtectedRoute>} />
    {/* <Route path={CONST_ROUTE_PATH.HOME} element={<PrivateRoute><Mainpage/></PrivateRoute>} /> */}
     <Route path={CONST_ROUTE_PATH.HOME} exact element={<Signin/>}/>
     {/* <Route path={CONST_ROUTE_PATH.SIGN_UP} exact element={<Signup/>}/> */}
     {/* <Route path={CONST_ROUTE_PATH.FORGOT_PASSWORD} exact element={<ForgotPassword/>}/> */}

    </Routes>
    </BrowserRouter>
    </ChatProvider>

  );
}

export default App;
