const API_URL = 'http://37.27.180.118:11434';

export const sendMessageToApi = async (message, onNewResponse) => {
  try {

    const body = {
      prompt: message.text,
      model: message.model,
      stream: message.stream // Assuming the API supports streaming
    };
    if (message.images.length !== 0) {
      body.images = message.images;
    }
    const bodyString = JSON.stringify(body);

    const response = await fetch(`${API_URL}/api/generate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: bodyString,
      
    });

    // Handle the streaming response
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let result = '';
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        result += decoder.decode(value, { stream: true });

        // Split the result into individual JSON objects
        const objects = result.split('\n').filter(line => line.trim().length > 0);
        result = ''; // Reset result to avoid reprocessing the same lines
        for (const obj of objects) {
          try {
            const parsed = JSON.parse(obj);
            if (parsed.response) {
              onNewResponse(parsed.response);
            }
          } catch (e) {
            console.error('Error parsing JSON:', e);
          }
        }
      }
    
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};
