import React from 'react';
import { Navigate } from 'react-router-dom'
import { CONST_ROUTE_PATH } from './Routes';
import { useAuth } from '../contexts/AuthContext';
export default function ProtectedRoute({ children }) {
    const { apiToken,
        backendToken, } = useAuth()
    if (!backendToken && !apiToken) {
        return <Navigate to={CONST_ROUTE_PATH.HOME} replace={true}></Navigate>
    }
    return children
}