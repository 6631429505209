import { REGISTRATION_TYPE } from "../Componants/Utils/Helper";
import APIConstants from "./ApiConstant";
import APIService from "./ApiServices";

/**
 * @description Login Api Service
 */
const LoginApiService = {
  // Manual Login
  manual: async (data) => {
    try {
      if (Object.keys(data).length > 0) {
        data['loginType'] = REGISTRATION_TYPE.MANUAL
      }

      return await APIService.post(APIConstants.ENDPOINT_SIGNIN, data)
    } catch (err) {
      console.error('Login Api Service: Caught error while login attempt', err, data)
    }
  },

  // Google Login
  google: async (data) => {
    try {
      if (Object.keys(data).length > 0) {
        data['loginType'] = REGISTRATION_TYPE.GOOGLE
      }

      return await APIService.post(APIConstants.ENDPOINT_SIGNIN, data)
    } catch (err) {
      console.error('Login Api Service: Caught error while login attempt', err, data)
    }
  },
};

export default LoginApiService;
