import React, { useContext, useState } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import MessageList from './MessageList';
import UserInput from './UserInput';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { ChatContext } from '../../contexts/ChatContext';
import Userdetails from '../Utils/Userdetails';

const ChatContainer = (props) => {
    const { conversations, activeConversation, setActiveConversation, sendMessage, updateMessage, messages, setstream } = useContext(ChatContext);

    const open = props.open;
    const activeMessages = conversations.find((conv) => conv.id === activeConversation)?.messages || [];

    const [isStreamOn, setIsStreamOn] = useState(true);

    const handleStreamChange = (event) => {
        const isChecked = event.target.checked;
        setIsStreamOn(isChecked);
        setstream(isChecked ? true : false); // This will set setstream to true or false based on isChecked
    };

    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 40,
        height: 24,
        padding: 0,
        display: 'flex',
        transition: theme.transitions.create('all', {
            duration: 200,
            easing: 'ease',
        }),
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 20,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(16px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 4,
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: 'white', // Background when checked
                },
                '& .MuiSwitch-thumb': {
                    backgroundColor: 'black', // Thumb color when checked
                },
            },
            '&:not(.Mui-checked)': {
                '& .MuiSwitch-thumb': {
                    backgroundColor: 'white', // Thumb color when unchecked
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 16,
            height: 16,
            borderRadius: 8,
            transition: theme.transitions.create('all', {
                duration: 200,
                easing: 'ease',
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 24 / 2,
            opacity: 1,
            backgroundColor: 'black', // Background when unchecked
            boxSizing: 'border-box',
            transition: theme.transitions.create('all', {
                duration: 200,
                easing: 'ease',
            }),
        },
    }));
    

    return (
        <Box sx={{ minHeight: '80vh', backgroundColor: '#333333', width: '100%'  }}>
            <Box color="white" sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography color="#F0F8FF" ml={open === true ? 5 : 15} mt={1} variant='h5'>Chat.Ai..</Typography>
                <Box sx={{ display: 'flex' }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography color="#F0F8FF">Stream Off</Typography>
                        <AntSwitch checked={isStreamOn} onChange={handleStreamChange} inputProps={{ 'aria-label': 'ant design' }} />
                        <Typography color="#F0F8FF">Stream On</Typography>
                    </Stack>
                    <Userdetails />
                </Box>
            </Box>
            <Container>
                <Box sx={{
                    mt: 3,
                    height: '73vh',
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    }
                }}>
                    <MessageList messages={activeMessages} updateMessage={updateMessage} msg={messages} />
                </Box>
                <Box sx={{ width: "100%" }}>
                    <UserInput sendMessage={sendMessage} />
                </Box>
            </Container>
        </Box>
    );
};

export default ChatContainer;
