import React,{useState} from 'react';
import { ListItem, ListItemText, IconButton, Box, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Import the copy icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import the check icon

const MessageItem = ({ message, updateMessage, messages }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = (messages) => {
    const textToCopy = Array.isArray(messages) ? messages.join(' ') : messages;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <ListItem>
      <Box sx={{ width: '100%' }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          color: 'black',
          minWidth: '20%',
          padding: '2vw',
          borderRadius: '2vw'
        }}>
          <Box sx={{ textAlign: 'end', width: '100%', color: 'black' }}>
            <ListItemText
              primary={
                <Typography variant="body1" sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#b4b1b1',
                  display: 'inline-block',
                  padding: '0.5vw 1vw',
                  borderRadius: '1vw'
                }}>
                  {message?.text}
                </Typography>
              }
            // secondary={message?.sender} 
            />

          </Box>
          {messages &&
            <Box>
              <ListItemText sx={{
                fontWeight: 'bold',
                backgroundColor: '#b4b1b1',
                display: 'inline-block',
                padding: '0.5vw 1vw',
                borderRadius: '1vw',
                mt: 2
              }}
                primary={messages} />

              <IconButton onClick={() => handleCopy(messages)} size="small" sx={{ ml: 1 }}>
                {copied ? <CheckCircleIcon fontSize="small" /> : <ContentCopyIcon fontSize="small" />}
              </IconButton>
            </Box>
          }
        </Box>
      </Box>
    </ListItem>
  );
};

export default MessageItem;
