import { useState } from 'react';
import { sendMessageToApi } from '../api/ChatApi';

const useChat = () => {
  const [messages, setMessages] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);

  const addChunkToMessages = (chunk) => {
    if (chunk) {
      setMessages((prevMessages) => [...prevMessages, chunk]);
    } else {
      console.log("Error: received undefined chunk");
    }
  };

  const sendMessage = async (text) => {
    setMessages([])
    setSearchHistory((prevHistory) => [...prevHistory, text]);
    
    try {
      await sendMessageToApi(text, addChunkToMessages);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return { messages, sendMessage, searchHistory };
};

export default useChat;
