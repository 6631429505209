import React, { useState, useEffect, useContext } from 'react';
import { Box, TextField, Button, InputAdornment, IconButton } from '@mui/material';
import { ChatContext } from '../../contexts/ChatContext';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import SendIcon from '@mui/icons-material/Send';

const UserInput = () => {
    const { sendMessage, updateMessage, messageBeingEdited, setMessageBeingEdited, activeConversation } = useContext(ChatContext);
    const [input, setInput] = useState('');
    const [image, setImage] = useState([]);

    useEffect(() => {
        if (messageBeingEdited) {
            setInput(messageBeingEdited.text);
        }
    }, [messageBeingEdited]);

    const handleSend = () => {
        if (messageBeingEdited) {
            updateMessage(activeConversation, messageBeingEdited.id, input);
            setMessageBeingEdited(null);
        } else {
            sendMessage(input, image);
        }
        setInput('');
        setImage([]);  // Clear the image after sending the message
    };

    const handleCancelEdit = () => {
        setMessageBeingEdited(null);
        setInput('');
        setImage([]);  // Clear the image if the edit is canceled
    };

    const handleImageSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];  // Remove the prefix
                setImage([base64String]);  // Store the base64 string in the state
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Box sx={{ display: 'flex',flexDirection:'column', alignItems: 'start' , }}>
             {image.length > 0 && (
                                <Box sx={{  display: 'flex', alignItems: "start", ml: 2, }}>
                                    <img
                                        src={`data:image/jpeg;base64,${image[0]}`}
                                        alt="Selected"
                                        style={{
                                            // position:'absolute',
                                            width: 90,
                                            height: 70,
                                            borderRadius:"1rem"
                                            // marginRight: 8,
                                        }}
                                    />
                                </Box>
                            )}
            <TextField 
                fullWidth
                variant="outlined"
                placeholder="Type a message"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment sx={{ mt: 1 }} position="start">
                           
                            <label htmlFor="input-file">
                                <AddToPhotosIcon sx={{ cursor: 'pointer' }} />
                            </label>
                            <input
                                type="file"
                                id="input-file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageSelect}
                            />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <IconButton
                            variant="contained"
                            onClick={handleSend}
                            sx={{ '&:hover': { color: '#008080' } }}
                            disabled={!input.trim() && !image.length} // Disable button if input and image are both empty
                        >
                            <SendIcon />
                        </IconButton>
                    )
                }}
                sx={{
                    backgroundColor: '#F0F8FF',
                    mt:1,
                    borderRadius: '2vw',
                    border: 'none',
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            border: 'none',
                        },
                        '&:hover fieldset': {
                            border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                            border: 'none',
                        },
                    },
                }}
            />

            {messageBeingEdited && (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleCancelEdit}
                    sx={{ ml: 2 }}
                >
                    Cancel
                </Button>
            )}
        </Box>
    );
};

export default UserInput;
