import { GoogleLogin } from '@react-oauth/google';
import React from 'react';

/**
 * Google Sign Up Component
 * @param {*} props 
 * @returns 
 */
const GoogleSignin = (props) => {
  const label = 'Google'

  /**
   * @description Google sign in success response handler
   * @param {*} response 
   */
  const handleSuccess = (response) => {
    console.log('GoogleSignUp Component: Sign up success response', response);
    props['handleSuccess'](response)

  };

  /**
   * @description Google sign in failure response handler
   * @param {*} error 
   */
  const handleFailure = (error) => {
    console.error('Google sign-up error:', error);
    props['handleFailure'](error)

  };

  return (
      <GoogleLogin
        buttonText={label}
        onSuccess={handleSuccess}
        onFailure={handleFailure}
        cookiePolicy={'single_host_origin'}
      />
  );
};

export default GoogleSignin;


