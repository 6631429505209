import { createContext, useContext,  useState } from 'react';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [apiToken, setApiToken] = useState(localStorage.getItem('apiToken') || null);
  const [backendToken, setBackendToken] = useState(localStorage.getItem('backendToken') || null);
  const [loggedIn, setLoggedIn] = useState(false);

  const loginWithApi = (token) => {
    setApiToken(token);
    setLoggedIn(true);
    localStorage.setItem('apiToken', token);
  };

  const loginWithGoogle = (token) => {
    setBackendToken(token);
    setLoggedIn(true);
    localStorage.setItem('backendToken', token);
  };

  const logout = () => {
    setApiToken(null);
    setBackendToken(null);
    setLoggedIn(false);

    localStorage.clear()
    sessionStorage.clear()
    // localStorage.removeItem('apiToken');
    // localStorage.removeItem('backendToken');
    // localStorage.removeItem('userDetails');
  };
  return (
    <AuthContext.Provider value={{ 
      apiToken, 
      backendToken, 
      loggedIn,
      loginWithApi, 
      loginWithGoogle, 
      logout,      
      }}>
      {children}
    </AuthContext.Provider>
  );
}
export const useAuth = () => useContext(AuthContext);