import * as React from 'react';
import { styled, useTheme, } from '@mui/material/styles';
import { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChatContainer from '../Pages/ChatContainer';
import SearchHistory from '../Pages/SearchHistory';
import { ChatContext } from '../../contexts/ChatContext';
import MenuOpenSharpIcon from '@mui/icons-material/MenuOpenSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import AddCommentSharpIcon from '@mui/icons-material/AddCommentSharp';

const drawerWidth = 300;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, isSmallScreen }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: isSmallScreen ? 0 : `-${drawerWidth}px`,
    ...(open && !isSmallScreen && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    ...(isSmallScreen && {
      width: '100%',
    }),
  }),
);

export default function Structure() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const isSmallScreen = useMediaQuery('(max-width:700px)');

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { conversations, setActiveConversation } = useContext(ChatContext);
  const handleNewChat = () => {
    setActiveConversation(null)
  }
  return (
    <Box sx={{ display: 'flex' }}>

    
      <Main sx={{ backgroundColor: '#333333', height: '100vh', display: 'flex' }} open={open} isSmallScreen={isSmallScreen}>
        <Box sx={{ ...(open && { display: 'none' }), position: 'absolute' }}>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            size="large"
            sx={{ ...(open && { display: 'none' }), color: 'white', }}
          >
            <MenuOpenSharpIcon />
          </IconButton>
          <IconButton
            onClick={handleNewChat}
            edge="end"
            sx={{ ...(open && { display: 'none' }), color: 'white', ml: '1rem' }}
          >
            <AddCommentSharpIcon />
          </IconButton>
        </Box>
        <ChatContainer open={open} />
      </Main>
    </Box>
  );
}
