import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, TextField, Link, Box, Divider, Avatar } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import GoogleSignin from './GoogleSignin';
import { CONST_ROUTE_PATH } from '../../../route/Routes';
import { REGISTRATION_TYPE, parseJwt } from '../../Utils/Helper';
import LoginApiService from '../../../services/LoginApiService';
import { useAuth } from '../../../contexts/AuthContext';


const Signin = () => {

  const navigate = useNavigate()
  const {
    loginWithGoogle,
    loginWithApi
  } = useAuth();

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
  });


  const handleSignIn = (values) => {
    console.log(values); // Implement your custom sign-in logic here

    const requestBody = {
      email: values.email,
      password: values.password,
    }
    LoginApiService.manual(requestBody)
      .then(response => {
        console.log(`Sign in component: ${REGISTRATION_TYPE.MANUAL} Login API response`, response)

        if (response && response['data']) {
          const loginToken = response['data']['loginToken']
          if (loginToken) {
            // Navigate on receiving token
            loginWithApi(loginToken)
            localStorage.setItem('email', requestBody['email'].toString())
            navigate(CONST_ROUTE_PATH.CHAT_AI)
          }
        } else if (response && response['error']) {
          const errMessage = response['error']['message']
          if (errMessage) {
            // Display error message to the user
          } else {
            console.warn(`Sign in component: response.error not found`, response)
          }
        } else {
          console.warn(`Sign in component: response.data not found`, response)
        }
      })


  };

  // const googleSuccessHandle = (data) => {
  //   // Reset API error message
  //   // setAPIErrorMessageForGoogle('')
  //   // setInProgress(true)

  //   const userData = parseJwt(data['credential'])
  //   const jsonData = data

  //   if (userData['email'] && userData['email'] !== '') {
  //     console.log('Sign in component: User data', userData)
  //     jsonData['user_info'] = userData

  //     const requestBody = {
  //       "email": userData['email'],
  //       "googleLoginToken": 'response.credential',
  //       "googleJson": JSON.stringify(jsonData)
  //     }
  //     //   Call registration api is form is valid
  //     LoginApiService.google(requestBody)
  //       .then(response => {
  //         console.log(`Sign in component: ${REGISTRATION_TYPE.GOOGLE} Login API response`, response)

  //         if (response && response['data']) {
  //           const loginToken = response['data']['loginToken']
  //           if (loginToken) {
  //             // Navigate on receiving token
  //             loginWithGoogle(loginToken)
  //             localStorage.setItem('email', requestBody['email'].toString())
  //             navigate(CONST_ROUTE_PATH.CHAT_AI)
  //           }
  //         } else if (response && response['error']) {
  //           const errMessage = response['error']['message']
  //           if (errMessage) {
  //             // Display error message to the user
  //             // setAPIErrorMessageForGoogle(errMessage)
  //           } else {
  //             console.warn(`Sign in component: response.error not found`, response)
  //           }
  //         } else {
  //           console.warn(`Sign in component: response.data not found`, response)
  //         }
  //       })
  //       .catch(e => {
  //         console.error(`Sign in component: Error caught google while login attempt`, e)
  //       })

  //   }
  // }
  // const googleFailureHandle = (error) => {
  //   console.error(`Sign in component: Error caught while google sign in`, error)
  // }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '2vw', width: "40rem" }}>
          <Avatar sx={{ m: 1, bgcolor: 'green' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography mt={2} variant="h4" gutterBottom>
            Sign In
          </Typography>
          {/* <Box mt={1}>
            <GoogleSignin
              handleSuccess={googleSuccessHandle}
              handleFailure={googleFailureHandle} />
          </Box> */}
          {/* <Divider sx={{ width: '80%', marginTop: 2 }}>OR</Divider> */}
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSignIn(values)}
          >
            {({ handleChange, handleBlur, handleSubmit, values, touched, errors }) => (
              <Form onSubmit={handleSubmit}>
                <Box mt={1}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Box>
                <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: "space-between" }}>
                  <Box >
                    {/* <RouterLink mt={2} to={CONST_ROUTE_PATH.FORGOT_PASSWORD} variant="body2">
                      Forgot Password?
                    </RouterLink>
                    <Typography component="p" variant="body2" sx={{ display: 'flex', alignItems: 'flex-end', paddingRight: '0', mt: 2 }}>
                      Don't have an account?&nbsp;
                      <RouterLink to='/sign-up'>
                        Sign up
                      </RouterLink>
                    </Typography> */}
                  </Box>
                  <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="success"
                    type="submit"
                  >
                    Sign In
                  </Button>
                </Box>

              </Form>
            )}
          </Formik>
          <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'space-between' }}>

          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Signin;
