import React, { createContext, useState } from 'react';
import useChat from '../hooks/useChat';

export const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null);
  const { messages, sendMessage: sendChatMessage } = useChat();
  const [stream, setstream]=useState(true)
  
  // const addMessageToConversation = (conversationId, message) => {
  //   setConversations((prevConversations) =>
  //     prevConversations.map((conv) =>
  //       conv.id === conversationId ? { ...conv, messages: [...conv.messages, message] } : conv
  //     )
  //   );
  // };

  const startNewConversation = (message) => {
    const newConversation = {
      id: Date.now(),
      messages: [message],
    };
    setConversations((prevConversations) => [...prevConversations, newConversation]);
    setActiveConversation(newConversation.id);
  };
  const sendMessage = async (text, images) => {
    const newMessage = { text, sender: 'user', id: Date.now() };
      startNewConversation(newMessage);
  
    try {
      const reqbody ={
        text : text,
        stream:stream,
        model:images.length !== 0? "llava":"llama3",
        images:images
      }
      console.log(reqbody)
      await sendChatMessage(reqbody);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <ChatContext.Provider
      value={{
        setstream,
        messages,
        conversations,
        activeConversation,
        setActiveConversation,
        sendMessage,
        
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
